import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import MainContent from './MainContent';
//import Header from './Header';
//import Footer from './Footer';

function App() {
  const [token, setToken] = React.useState(() => localStorage.getItem('token') || null);
//  const [isTokenValid, setIsTokenValid] = React.useState(true);



  // Save token to localStorage whenever it changes
  React.useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token'); // Clean up on logout
    }
  }, [token]);

  return (
    <Router>
      {/* Optionally include Header */}
      {/* <Header /> */}

      <Routes>
        {/* Route for Login */}
        <Route path="/Login" element={<Login setToken={setToken} />} />

        {/* Protected Route for Main Content */}
        <Route
          path="/*"
          element={
            <ProtectedRoute
              component={MainContent}
              token={token}
              setToken={setToken}
            />
          }
        />
      </Routes>

      {/* Optionally include Footer */}
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
