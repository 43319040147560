import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import { backendUrl } from './Exports.js';

function Login({ setToken }) {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch(`${backendUrl}token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'username': username,
          'password': password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setToken(data.access_token); // Store the token
        navigate('/'); // Redirect to main content page
      } else {
        const errorData = await response.json();
        alert(`Login failed: ${errorData.detail || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>QX-Builder</h1>
          <img src={logo} className="App-logo" alt="logo" />
        <form onSubmit={handleSubmit}>
          <h2>Login</h2>
          <label>
            Username:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
          <br />
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <br />
          <button type="submit">Login</button>
        </form>
      </header>
    </div>
  );
}

export default Login;
