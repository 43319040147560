import React from 'react';

import {backendUrl} from '../Exports.js';  // Imports the backend URL
// const downloadCommand = "download-sample-file";

const DownloadTestFileButton = () => {
  const handleDownload = () => {
    // Construct the URL to your backend endpoint
        const sampleUrl = new URL('download-sample-file/', backendUrl);  // Appends download command to imported backend URL

    // Use the browser's built-in functionality to download the file
    window.location.href = sampleUrl;
    console.log(sampleUrl);
  };

  return (
    <div>
      <button onClick={handleDownload}>
        Download Test File
      </button>
    </div>
  );
};

export default DownloadTestFileButton;
