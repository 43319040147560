import React from 'react';
import FileDownloader from './components/FileDownloader';
import DownloadTestFileButton from './components/DownloadTestFileButton';
import logo from './logo.svg';
import './App.css';

function MainContent({ token, setToken }) {
  // Your main page content goes here

  const handleLogout = () => {
    setToken(null); // Clear the token to log out
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>QX-Builder</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <FileDownloader />
        <DownloadTestFileButton />
        {/* Include other components and content as needed */}
        <button onClick={handleLogout}>Logout</button>
      </header>
    </div>
  );
}

export default MainContent;
