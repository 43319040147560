import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ component: Component, token, setToken }) {
  // If the user is not authenticated, redirect to Login
  if (!token) {
    return <Navigate to="/Login" replace />;
  }

  // If authenticated, render the component
  return <Component token={token} setToken={setToken} />;
}

export default ProtectedRoute;
