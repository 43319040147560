import React, { useState } from 'react';
import axios from 'axios';

import {backendUrl} from '../Exports.js'  // Imports the backend URL

function FileDownloader() {
  const [company, setCompany] = useState('');
  const [department, setDepartment] = useState('');
  const [message, setMessage] = useState('');

  const handleDownload = async (e) => {
    e.preventDefault();
    setMessage('Processing your request...');

    try {
      // Send a POST request to the backend
      const response = await axios.post(
        new URL('getfile/', backendUrl),  // Appends download command to imported backen URL
        {
          company: company,
          department: department
        },
        {
          responseType: 'blob' // Important for handling binary data
        }
      );

      // Create a URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Extract filename from headers
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'downloaded_file.exe';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch.length === 2) filename = filenameMatch[1];
      }

      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setMessage('Download started.');
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while processing your request.');
    }
  };

  return (
    <div>
      <h1>File Downloader</h1>
      <form onSubmit={handleDownload}>
        <div>
          <label>Company:</label>
          <input
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Department:</label>
          <input
            type="text"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            required
          />
        </div>
        <button type="submit">Download File</button>
      </form>
      <p>{message}</p>
    </div>
  );
}

export default FileDownloader;